<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-button
      class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
      @click="$router.go(-1)"
    >
      <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
    </b-button>

    <b-row class="match-height">
      <b-col md="6">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('details') }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('orderNumber') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    {{ items.itemnumber_text }}
                  </p>
                </b-form-group>

              </b-col>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('customerCode') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    <router-link
                      :to="{name : 'admin-employee-edit', params: {id: items.uid}}"
                    >
                      {{ items.username }}
                    </router-link>
                  </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label-for="h-payTaobao"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('payTaobao') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    <router-link
                    v-if="items.tao_id"
                      :to="{name : 'admin-china-payfor-check', params: {id: items.tao_id}}"
                    >
                      {{ items.tao_itemnumber_text }} 
                    </router-link>
                    <span v-else>-</span>
                    <span v-if="items.tao_id">{{ $t('exchangeRate') }} {{ items.tao_rate }} {{ $t('baht') }}</span>
                  </p>
                </b-form-group>

              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('mnemonic') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    {{ items.keyword }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('mnemonicPhrase') }} :
                  </template>
                  <div>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="items.keyword_admin"
                      rows="2"
                      @change="Updatekey()"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="6">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('relatedAmountList') }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-table
                  responsive="sm"
                  :fields="fieldsRelevant"
                  :items="Transactions"
                  show-empty
                >
                  <template #empty="">
                    <b-table-empty-slot>
                      <div class="text-center my-2">
                        <img
                          src="@/assets/images/anan-img/svg/doc_empty.svg"
                          alt="empty"
                          height="60px"
                        >
                        <p class="text-muted">
                          No Data
                        </p>
                      </div>
                    </b-table-empty-slot>
                  </template>

                  <template #cell(created_at)="data">
                    {{ time(data.item.created_at) }}
                    <!-- <span :style="getAmountStyle(data.item.type)">
                      {{ getAmountPrefix(data.item.type) }}
                      {{ Commas(data.item.amount) }} ฿
                    </span> -->
                  </template>

                  <template #cell(amount)="data">

                    <span
                      :style="getAmountStyle(data.item.type,  data.item.type_amount)"
                    >{{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿</span>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="12">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('key-60') }}
            </h3>
          </div>

          <div class="pl-2 pr-2">
            <b-table
              responsive="sm"
              :fields="fieldsRefund"
              :items="items.products"
              show-empty
            >
              <template #empty="">
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>

              <template #cell(number)="data">
                {{ data.index+1 }}
              </template>
              <template #cell(detail)="data">
                {{ data.item.order_id }}
              </template>
              <template #cell(refund_time)="data">
                {{ data.item.date_approve }}
              </template>
              <template #cell(img_refund)="data">
                <div
                  v-if="data.item.file_refer"
                  class="preview-image-container mr-25 d-flex justify align-items-center"
                >
                  <img
                    :src="GetImg('Shop_refund_file',data.item.file_refer)"
                    alt="Preview"
                    width="100px"
                    height="50px"
                    @click="showPicture(data.item.file_refer)"
                  >

                  <vue-image-lightbox-carousel
                    ref="lightbox"
                    :show="showLightbox"
                    :images="images"
                    :show-caption="false"
                    @close="closeBox()"
                  />

                </div>
                <!-- {{ data.item.date_approve }} -->
              </template>
              <template #cell(amount)="data">
                ¥ {{ data.item.price }}
              </template>
              <template #cell(action)="data">
                <!-- {{ data.item.status }} -->
                <div
                  v-if="data.item.status == 1"
                  class="row"
                >
                  <div class="col-5">
                    <button
                      class="btn btn-danger "
                      style="font-size: 10px;"
                      @click="canceldata(data.index)"
                    >{{ $t('cancel') }}</button>
                  </div>
                  <div class="col-7">
                    <button
                      class="btn btn-success"
                      style="font-size: 10px;"
                      @click="Submitpay(data.index)"
                    >{{ $t('key-30') }}</button>
                  </div>
                </div>
                <div
                  v-if="data.item.status == 0"
                  class="row"
                >

                  <div class="col-6 badge badge-light-secondary text-black">
                    {{ $t('cancelled') }}

                  </div>
                  <span class="text-danger">*{{ data.item.cancel_by }}</span>
                </div>
                <div
                  v-if="data.item.status == 2"
                  class="row"
                >

                  <div class="col-6 badge badge-light-success text-black">
                    {{ $t('operationCompleted') }}
                  </div>

                </div>

              </template>
            </b-table>
          </div>
          <div
            class="pl-2 pr-5 p-2"
          >
            <b-row align-h="between">
              <b-col cols="5" />
              <b-col cols="3">
                <p class="font-weight-bold h4">
                  {{ $t('totalRefund') }} :  {{ items.products.reduce((total,acc) => total+ (acc.status == 2 ? acc.price: 0),0) }} ¥
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BTable,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: true,
      Transaction: [],

      items: [],
      Transations: [],
      ID: this.$route.params.id,
      Transactions: [],
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'created_at',
          label: this.$t('transactionDate'),
          thStyle: { width: '20%' },
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        {
          key: 'amount',
          label: this.$t('quantity'),
          thStyle: { width: '20%' },
          formatter: value => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ]
    },
    fieldsRefund() {
      return [
        {
          key: 'number',
          label: this.$t('sequence'),
          thStyle: { width: '10%' },
        },
        {
          key: 'detail',
          label: this.$t('orderList'),
          thStyle: { width: '20%' },
        },
        {
          key: 'refund_time',
          label: this.$t('key-61'),
          thStyle: { width: '20%' },
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          key: 'img_refund',
          label: this.$t('key-62'),
          thStyle: { width: '20%' },
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          key: 'amount',
          label: this.$t('price'),
          thStyle: { width: '10%' },
        },
        {
          key: 'action',
          label: this.$t('manage'),
          thStyle: { width: '12%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getTransactions()
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Payforchina/getData/RefundOne', { params })
        this.items = res
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    async getTransactions() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })

        if (res.success) {
          this.Transactions = res.data
          // this.getTransactionsChina(res.data)
        }
      } catch (err) {
        console.log(err)
        this.SwalError(err)
      }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Shop_refund_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    canceldata(index) {
      this.$bvModal.msgBoxConfirm(`ต้องการยกเลิกรายการ ยอด ¥ ${this.items.products[index].price} ?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const obj = {
            id: this.ID,
            index,
          }
          this.$http.post('/Payforchina/Cancel/refund', obj)
            .then(() => {
              this.getData()
              this.Success('ยกเลิกข้อมูลสำเร็จ')
              this.getTransactions()
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    Submitpay(index) {
      this.$bvModal.msgBoxConfirm(`ต้องการอนุมัติจ่ายแล้ว ยอด ¥ ${this.items.products[index].price} ?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const obj = {
            id: this.ID,
            index,
          }
          this.$http.post('/Payforchina/Update/refund', obj)
            .then(() => {
              this.getData()
              this.Success(this.$t('key-260'))
              this.getTransactions()
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    Updatekey() {
      const obj = {
        id: this.ID,
        keyword_admin: this.items.keyword_admin,
      }
      this.$http.post('/Payforchina/Update/keyword', obj)
        .then(() => {
          this.getData()
          this.Success(this.$t('key-260'))
          this.getTransactions()
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
          if (typeAmount) {
            return { color: 'green' }
          } else {
            return { color: 'red' }
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(amount)) {
              return { color: 'green' }
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
              return { color: 'red' }
            }
        }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
          if (typeAmount) {
            return '+'
          } else {
            return '-'
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(value)) {
              return '+'
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
                return '-'
            }
        }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
